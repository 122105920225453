import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";

import Player_v1 from './components/Player_v1';
import Player_v2 from './components/Player_v2';
import Player_v3 from './components/Player_v3';

@inject('PlayerStore')
@withRouter
@observer
class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      asset: {},
      background: "#192e5b",
      showQR: true
    };
  }

  componentDidMount = () => {
    this.props.PlayerStore.start();
  }

  render() {
    const { asset } = this.props.PlayerStore;
    const background = asset && asset.darkbg_background ? asset.darkbg_background : this.state.darkbg_background;
    const color = asset && asset.darkbg_text_color ? asset.darkbg_text_color : this.state.darkbg_text_color;

    const width = window.innerWidth,
      height = window.innerHeight;
    let player = <div />;

    if (width >= 1440 && height >= 1000) {
      player = (
        <Player_v1 
          asset={asset} 
          showQR={asset && typeof asset.is_qr == "boolean" ? asset.is_qr : this.state.showQR}
        />
      );
    } else if (width <= 700) {
      player = (
        <Player_v2
          asset={asset} 
          showQR={asset && typeof asset.is_qr == "boolean" ? asset.is_qr : this.state.showQR}
        />
      );
    } else {
      player = (
        <Player_v3
          asset={asset}
          showQR={asset && typeof asset.is_qr == "boolean" ? asset.is_qr : this.state.showQR}
        />
      );
    }

    return (
      <div className="app-wrapper" 
        style={{ 
          background: background,
          color: color
        }}
      >
        {player}
      </div>
    );
  }
}

export default App;
