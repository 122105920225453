import React, { Component } from 'react';
import QRCode from 'qrcode-react';
import './player_v3.scss';
import domainLogo from 'images/domain_gr.png';
import enplugLogo from 'images/enplug_logo_v2.png';
import domainSmallLogo from 'images/domain-small.jpg';
import enplugSmallLogo from 'images/enplug-small.jpg';
import { reaction } from "mobx";
import { observer, inject } from 'mobx-react';

// Player for landscape design (1080x1440)
@inject('PlayerStore')
@observer
export default class Player_v3 extends Component {
  constructor(props) {
    super(props);

    this.statuses = {
      sale: "For Sale",
      rent: "For Rent"
    };

    this.state = {
      isShownBacground: false
    };
  }

  componentDidMount = () => {
    reaction(
      () => this.props.PlayerStore.listing,
      () => {
        const { listing } = this.props.PlayerStore;
        this.setState({
          isShownBacground: true
        });

        let images = [];
        if (listing) {
          for (var i = 1; i <= 4; i++) {
            images.push(listing.media[i].url);
          }
        }

        this.loadImages(...images).then(result => {
          this.toggleBackdrop();
        });
      }
    );
  }

  render() {
    const { listing, agent } = this.props.PlayerStore;

    if (!listing || !listing.id || !this.props.asset) {
      return <div id="player" />;
    }

    const { for_sale_background, for_sale_text_color, lightbg_background, lightbg_text_color, darkbg_background, darkbg_text_color } = this.props.asset;

    return (
      <div className="player player-v3"
        style={{
          background: darkbg_background,
          color: darkbg_text_color
        }}
      >
        <div id="player"></div>
        <div className="container-fluid">
          <div className="images">
            <div className="image-row row no-gutters">
              <div className="top" id="top-image">
                <img src={listing.media[0].url} alt="" />
              </div>
              {listing.media[1] ? (
                <div className="image-container">
                  <img src={listing.media[1].url} className="img-fluid" alt="" />
                </div>
              ) : (
                ''
              )}
              {listing.media[2] ? (
                <div className="image-container">
                  <img src={listing.media[2].url} className="img-fluid" alt="" />
                </div>
              ) : (
                ''
              )}
              {listing.media[3] ? (
                <div className="image-container">
                  <img src={listing.media[3].url} className="img-fluid" alt="" />
                </div>
              ) : (
                ''
              )}
              {listing.media[4] ? (
                <div className="image-container">
                  <img src={listing.media[4].url} className="img-fluid" alt="" />
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="status-wrapper">
            <div className="status"
              style={{ 
                background: for_sale_background,
                color: for_sale_text_color
              }}
            >
              {this.statuses[listing.objective]}
            </div>
          </div>
          <div className="info-card">
            <div className="info row no-gutters"
              style={{ 
                background: lightbg_background,
                color: lightbg_text_color
              }}
            >              
              <div className="col-sm-9 left-column">
                <div className="address-label">Apartment / Unit</div>
                <div className="address" id="address">
                  {listing.addressParts ? listing.addressParts.displayAddress: ""}
                </div>
                <div className="price" id="price">
                  {listing.priceDetails ? listing.priceDetails.displayPrice : ""}
                </div>
              </div>  
              {this.renderCounters(listing)}            
              <div className="qr">
                { this.props.showQR ? <QRCode size={115} value={`https://domain.com.au/${listing.id}`} /> : <div /> }
              </div>              
            </div>
            
            <div className="row both-row">
              <div className="description col-sm-6 desctiption-column" id="description">
                {listing.description}
              </div>
              <div className="col-sm-6 agent-column"
                style={{ 
                  background: lightbg_background,
                  color: lightbg_text_color
                }}
              >
                {this.renderAgent(agent)}
                <div className="hidden-items">
                  <div className="logos">
                    <img src={domainSmallLogo} className="domain-logo" alt=""/>
                    <img src={enplugSmallLogo} alt="enplug-logo" alt=""/>
                  </div>
                  <div className="qr">
                    { this.props.showQR ? <QRCode size={115} value={`https://domain.com.au/${listing.id}`} /> : <div /> }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="row align-items-end logos-wrapper" 
              style={{ 
                background: darkbg_background,
                color: darkbg_text_color
              }}
            >
              <div className="col-4 col-sm-3 text-left domain-column">
                <img src={domainLogo} className="domain-logo" alt=""/>
              </div>
              <div className="col-sm-6 agent-column" />
              <div className="col-4 offset-4 offset-sm-0 col-sm-3 text-right enplug-column">
                <div>Powered by</div>
                <img src={enplugLogo} alt=""/>
              </div>
            </div>          
          </div>
        </div>
        <div className="backdrop"
          style={{ 
            background: darkbg_background,
            color: darkbg_text_color,
            opacity: this.state.isShownBacground ? 1 : 0
          }}
        >
          <div className="message">Loading..</div>
        </div>
      </div>
    );
  }

  renderCounters = (listing) => {
    return (
      <div className="counters">
        <div className="badrooms">
          <i className="fas fa-bed" />
          <div>
            {listing.bedrooms && listing.bedrooms != "0" ? listing.bedrooms : "-"}
          </div>
        </div>
        <div className="bathrooms">
          <i className="fas fa-bath" />
          <div>
            {listing.bathrooms && listing.bathrooms != "0" ? listing.bathrooms : "-"}
          </div>
        </div>
        <div className="carspaces">
          <i className="fas fa-car" />
          <div>
            {listing.carspaces && listing.carspaces != "0" ? listing.carspaces : "-"}
          </div>
        </div>
      </div>
    );
  }

  renderAgent = (agent) => {
    if (!agent) {
      return <div className="agent" />;
    }

    return (
      <div className="agent">
        <div className="avatar">
          <img src={agent.photo} />
        </div>
        <div className="body">
          <div className="name">
            {`${agent.firstName} ${agent.lastName}`}
          </div>
          <div className="contact">
            { agent.phone }
          </div>
        </div>
      </div>
    );
  }

  loadImages = (...paths) => {
    return Promise.all(paths.map(this.checkImage));
  }

  checkImage = (path) => {
    return new Promise(resolve => {
      const img = new Image();
      img.onload = () => resolve({path, status: 'ok'});
      img.onerror = () => resolve({path, status: 'error'});

      img.src = path;
    });
  }

  toggleBackdrop = (isShown) => {
    this.setState({
      isShownBacground: isShown
    });
  }
}
