import { autorun, observable } from "mobx";
import axios from "axios";
import md5 from "crypto-js/md5";
import('babel-polyfill');

class PlayerStore {
  @observable asset = null;
  @observable listing = null;
  @observable listings = {
    all: null,
    sale: null,
    rent: null
  };
  @observable token = null;
  @observable token_server = null;
  @observable agent = null;

  constructor() {
    this.domainUrl = "https://api.domain.com.au/v1";
    this.serverUrl = env == "production" ? "https://api.realestate.stampngo.com" : "https://dev.api.realestate.stampngo.com";
  }

  start = () => {
    window.enplug.appStatus.start();
    window.enplug.assets.getNext()
    .then(this.getAssets)
    .then(asset => {
      if (asset.status) {
        this.interruptPlayer(false);
      }
    })
    .catch(this.handleError);
  }

  getAssets = (asset) => {
    this.asset = asset;
    if (typeof asset == "object" && asset.agency_id) {
      this.getListings(asset);
    } else if (typeof asset == "object") {
      this.getAsset(asset.itemId ? asset.itemId : asset.id);
    }

    return asset;
  }

  getAsset = (id) => {
    this.getListing(id);
  }

  getServerToken = () => {
    return "player_" + md5("playernathan");
  }

  getDomainToken = () => {
    const authUrl = "https://auth.domain.com.au/v1/connect/token",
      client_id = 'client_bddb501dbd0f4d8a9adaf82253f7c788',
      client_secret = 'secret_b620b7ea6c9fbc133d61bba3ce84d476';

    let params = new URLSearchParams();
    params.append("client_id", client_id);
    params.append("client_secret", client_secret);
    params.append("grant_type", "client_credentials");
    params.append("scope", "api_agencies_read api_listings_read");

    return axios.post(authUrl, params)
    .then(response => {
      this.token = response.data.access_token;
      return response;
    })
    .catch(this.handleError);
  }

  getListing = (id) => {
    return this.getServerListing(id)
    .then(response => {
      this.listing = JSON.parse(response.value);
      this.getAgent();
    })
    .catch(e => {
      return this.getDomainListing(id)
      .then(response => {
        this.putServerListing(id, response);
        this.listing = response;
        this.getAgent();
      });
    });
  }

  getDomainListing = (id) => {
    const url = `${this.domainUrl}/listings/${id}`;

    return this.getDomainToken()
    .then(r => {
      return axios.get(url, { 
        headers: {"Authorization" : `Bearer ${this.token}`}
      })
      .then(response => response.data);
    });
  }

  getServerListing = (id) => {
    const url = `${this.serverUrl}/listings/${id}`;

    return axios.get(url, {
      headers: {
        'Authorization': this.getServerToken()
      }
    })
    .then(response => response.data);
  }

  putServerListing = (id, item) => {
    const url = `${this.serverUrl}/listings`;
    const formData = this.getFormData({
      id: id,
      value: JSON.stringify(item)
    });

    return axios.post(url, formData, { headers: { 
      'Authorization': this.getServerToken(),
      'Content-Type': 'multipart/form-data' 
    } });
  }

  setAgent = () => {
    const agent_ids = this.listing ? this.listing.advertiserIdentifiers.contactIds : null;
    if (this.listing && agent_ids) {
      this.getAgent(agent_ids[0])
      .then(agent => {
        this.agent = agent;
      })
      .catch(this.handleError);
    }
  }

  getAgent = () => {
    if (this.listing && this.listing.advertiserIdentifiers.contactIds) {
      const id = this.listing.advertiserIdentifiers.contactIds[0];

      return this.getServerAgent(id)
      .then(response => {
        this.agent = JSON.parse(response.value);
      })
      .catch(e => {
        return this.getDomainAgent(id)
        .then(response => {
          this.putServerAgent(id, response);
          this.agent = response;
        });
      });
    }
  }

  getDomainAgent = (id) => {
    const url = `${this.domainUrl}/agents/${id}`;

    return this.getDomainToken()
    .then(r => {
      return axios.get(url, { 
        headers: {"Authorization" : `Bearer ${this.token}`}
      })
      .then(response => response.data);
    });
  }

  getServerAgent = (id) => {
    const url = `${this.serverUrl}/agents/${id}`;

    return axios.get(url, {
      headers: {
        'Authorization': this.getServerToken(),
      }
    })
    .then(response => response.data);
  }

  putServerAgent = (id, item) => {
    const url = `${this.serverUrl}/agents`;
    const formData = this.getFormData({
      id: id,
      value: JSON.stringify(item)
    });

    return axios.post(url, formData, { headers: { 
      'Authorization': this.getServerToken(),
      'Content-Type': 'multipart/form-data' 
    } });
  }

  getListings = (asset) => {
    const url = `${this.domainUrl}/agencies/${asset.agency_id}/listings`;
    let data = {
      params: {
        pageNumber: 1,
        pageSize: 200
      },
      headers: {"Authorization" : `Bearer ${this.token}`}
    }

    return axios.get(url, data)
    .then(response => {
      if (asset.status) {
        this.listings[asset.status] = this.getListinsByType(response.data, asset.status);
        this.repeatListings(asset.status);
      } else {
        this.listings.all = response.data;
      }
    });
  }

  getListinsByType = (listings, status) => {
    let result = [];
    listings.forEach(listing => {
      if (listing.objective == status || status == "all") {
        result.push(listing);
      }
    });

    return result;
  }

  repeatListings = (status) => {
    this.listing = this.listings[status][0];
    this.setAgent();
    this.timeout(1, status);
  }

  timeout = (index, status) => {
    const waitTime = 15000;
    setTimeout(() =>{
      this.listing = this.listings[status][index];
      this.getAgent();

      if (index < this.listings[status].length - 1) {
        this.timeout(index + 1, status);
      } else {
        this.interruptPlayer(true);
        setTimeout(() =>{ this.start() }, waitTime);
      }
    }, waitTime);
  }

  interruptPlayer = (value) => {
    window.enplug.appStatus.setCanInterrupt(value);
  }

  handleError = (error) => {
    console.log(error);

    if (error.response.status == 401) {
      this.getToken()
      .then(r => {
        window.enplug.appStatus.hide();
        this.start();
      })
      .catch(this.handleError);
    } else {
      let div = document.createElement("div");
      div.innerHTML = `<div>${error.message}</div><div>${error.stack}</div>`;
      document.getElementById('player').append(div);
    }
  }

  getFormData = (obj) => {
    let formData = new FormData();

    for ( const key in obj ) {
      if (!Array.isArray(obj[key])) {
        formData.append(key, obj[key]);
      } else {
        obj[key].forEach((item, index) => {
          formData.append(`${key}[${index}]`, item);
        });
      }
    }

    return formData;
  }
}

const store = window.PlayerStore = new PlayerStore;

export default store;

autorun(() => {
  // console.log(store);
});